var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "me-detail-comments",
      style: {
        borderBottom: _vm.noBottomBorder ? "none" : "6px solid #f5f5f5"
      }
    },
    [
      _vm.showCount
        ? _c("label", { on: { click: _vm.handlerReplayClick } }, [
            _c("i", {
              staticClass: "me-icon-edit",
              staticStyle: { color: "#066cc3" }
            }),
            _c("span", [_vm._v(_vm._s(_vm.count))])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "me-detail-comments__list" },
        _vm._l(Object.keys(_vm.list), function(item, index) {
          return _c(
            "div",
            {
              key: "key1" + index,
              staticClass: "me-detail-comments__list--item"
            },
            [
              _c("Avatar", {
                key: "key2" + index,
                attrs: {
                  src: _vm.list[item].target.userAvatar,
                  id: _vm.list[item].target.reviewerId,
                  width: "40px",
                  height: "40px"
                }
              }),
              _c(
                "div",
                { staticClass: "info" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "user",
                      on: {
                        click: function($event) {
                          return _vm.jumpUserPage(
                            _vm.list[item].target.reviewerId
                          )
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.list[item].target.reviewer) +
                          "\n          "
                      ),
                      _c("span", {
                        staticClass: "time",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.list[item].target.reviewDatetime
                          )
                        }
                      })
                    ]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "content",
                      on: {
                        click: function($event) {
                          return _vm.handlerReplayViews(
                            _vm.list[item].target.reviewerId
                          )
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("reconvert")(_vm.list[item].target.content)
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm.list[item].target.children.length
                    ? _vm._l(_vm.list[item].target.children, function(
                        item,
                        index
                      ) {
                        return _c(
                          "div",
                          { key: "key3" + index, staticClass: "info__child" },
                          [
                            _c("div", { staticClass: "info__child--item" }, [
                              _c("span", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.reviewer) +
                                    "\n                "
                                ),
                                _c("i", { staticClass: "me-icon-replay" }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.parentReviewer) +
                                    "\n              "
                                )
                              ]),
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.handlerReplayViews(
                                        item.reviewerId
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm._f("reconvert")(item.content))
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      })
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }