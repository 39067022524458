<template>
  <div class="me-detail-comments" :style="{
    borderBottom: noBottomBorder ? 'none' : '6px solid #f5f5f5'
  }">
    <label @click="handlerReplayClick" v-if="showCount">
      <i class="me-icon-edit" style="color:#066cc3"></i>
      <span>{{ count }}</span>
    </label>
    <div class="me-detail-comments__list">
      <div class="me-detail-comments__list--item" v-for="(item, index) in Object.keys(list)" :key="'key1' + index">
        <Avatar :src="list[item].target.userAvatar" :key="'key2' + index" :id="list[item].target.reviewerId"
          width="40px" height="40px" />
        <div class="info">
          <div class="user" @click="jumpUserPage(list[item].target.reviewerId)">
            {{ list[item].target.reviewer }}
            <span v-html="list[item].target.reviewDatetime" class="time"></span>
          </div>
          <span class="content" @click="handlerReplayViews(list[item].target.reviewerId)">
            {{ list[item].target.content | reconvert }}
          </span>
          <template v-if="list[item].target.children.length">
            <div class="info__child" v-for="(item, index) in list[item].target.children" :key="'key3' + index">
              <div class="info__child--item">
                <span>
                  {{ item.reviewer }}
                  <i class="me-icon-replay"></i>
                  {{ item.parentReviewer }}
                </span>
                <span @click="handlerReplayViews(item.reviewerId)">{{ item.content | reconvert }}</span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Base64 } from "js-base64";
import { reply } from "@/api/webApi";
import { componentCheckHasLogin } from "@/utils/help";
import Avatar from "@/components/common/avatar";
import { reconvert } from "@/utils/unicode"
export default {
  name: "comments",
  data() {
    return {
      list: {},
      params: {
        start: 0,
        limit: 9999,
        query_no_cache: true
      },
      count: ""
    };
  },
  components: {
    Avatar
  },
  props: {
    id: {
      type: String,
      default: ""
    },
    // 是否展示顶部评论统计
    showCount: {
      type: Boolean,
      default: true
    },
    // 评论类型
    type: {
      type: String,
      default: ""
    },
    // 不用展示底部边框
    noBottomBorder: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    id: {
      handler(val, old) {
        if (val && val !== old) {
          this.params.table_name_replace =
            localStorage.getItem("langCode") !== null
              ? localStorage.getItem("langCode")
              : "1";
          this.getComments();
        }
      },
      deep: true,
      immediate: true,
    }
  },
  filters: {
    reconvert(s) {
      return reconvert(s)
    }
  },
  methods: {
    componentCheckHasLogin,
    jumpUserPage(id) {
      this.$router.push({
        name: 'user',
        params: { id: "pai_" + Base64.encode(id) }
      })
    },
    handlerReplayClick() {
      this.componentCheckHasLogin();
      this.$emit("handlerReplay", true);
    },
    async getComments() {
      switch (this.type) {
        case "user":
          this.getUserComments();
          break;
        case "":
        case "service":
          this.getServiceComments();
          break;
        case "group":
          this.getGroupComments();
          break;
        case "enterprise":
          this.getEnterComments();
          break;
      }
    },
    async getEnterComments() {
      this.params.enterprise_id = this.id;
      this.params.funcId = "hex_helome_queryEnterpriseReviewsByEnterpriseId";
      await this.getAllCommentsFunc();
    },
    async getServiceComments() {
      this.params.gig_id = this.id;
      this.params.funcId = "hex_helome_queryHelogigReviewsByGigId";
      await this.getAllCommentsFunc();
    },
    async getGroupComments() {
      this.params.group_id = this.id;
      this.params.funcId = "hex_helome_queryGroupReviewsByGroupId";
      await this.getAllCommentsFunc();
    },
    async getUserComments() {
      this.params.provider_id = this.id;
      this.params.funcId = "hex_helome_queryProviderReviewsByProviderId";
      await this.getAllCommentsFunc();
    },
    async getAllCommentsFunc() {
      // let result = await publicDo(this.params, "/public/data.do").catch(
      //   e => { }
      // );
      let param2 = null;
      if (this.params.gig_id) {
        param2 = {
          gid: this.params.gig_id,
          operation: 1,
        }
      }
      if (this.params.group_id) {
        param2 = {
          gid: this.params.group_id,
          operation: 2,
        }
      }
      if (this.params.provider_id) {
        param2 = {
          gid: this.params.provider_id,
          operation: 3,
        }
      }


      let result2 = await reply(param2).catch(e => { })

      if (result2 && result2.data.data) {
        this.list = this.formartComment(result2.data.data);
        this.count = result2.data.data.length.toString() || "";
      }
    },
    async handlerReplayViews(id) {
      let type = 1;
      switch (this.type) {
        case "service":
          type = 1;
          break;
        case "user":
          type = 3;
          break;
        case "enterprise":
          type = 5;
          break;
        case "group":
          type = 4;
          break;
      }
      let params = {
        operationId: this.id,
        reviewId: id,
        operation: type
      };
      this.$emit("replayChild", params);
      // console.log(params, "/params");
    },
    formartComment(data) {
      var reviews = {};
      let items = data || [];
      for (var i = 0; i < items.length; i++) {
        var item = items[i];
        item.replay = false;
        if (
          item.parentReviewId &&
          item.parentReviewId != "" &&
          item.parentReviewId != "-1"
        ) {
          item.replay = false;
          if (reviews[item.parentReviewId] == null) {
            reviews[item.parentReviewId] = {
              children: [item]
            };
          } else {
            reviews[item.parentReviewId].children.unshift(item);
          }
        } else {
          if (reviews[item.reviewId] == null) {
            reviews[item.reviewId] = {
              target: item,
              children: []
            };
          } else {
            reviews[item.reviewId].target = item;
          }
        }
      }
      return reviews;
    }
  },
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';

+b(me-detail-comments) {
  display: flex;
  flex-flow: column;
  padding: 0.9rem;
  align-items: flex-start;
  
  +e(list) {
    display: flex;
    flex-flow: column;

    +m(item) {
      display: flex;
      flex-flow: row;
      margin: 0.9rem 0;

      .info {
        display: flex;
        flex-flow: column;
        margin: 0;
        text-align: left;

        span {
          margin-bottom: 0.25rem;

          &.time {
            font-size: 12px;
            color: #999999;
          }

          &.content {
            margin: 0.3rem 0;
            word-break: break-all;
            line-height: 22px;
            font-size: 14px;
          }
        }

        +e(child) {
          +m(item) {
            word-break: break-all;
            background: #f5f5f5;
            border: 1px solid #e5e5e5;
            font-size: 14px;
            padding: 0.25rem;
            margin: 0.25rem 0;
            color: #333333;
            line-height: 22px;

            span {
              color: #999999;
            }
          }
        }
      }
    }
  }

  label {
    margin: 0.5rem 0;
    font-size: 16px;
    color: #999999;
    display: flex;
    align-items: center;
    width: 100%;

    i {
      font-size: 22px;
      margin-right: 0.25rem;
    }
  }
}
</style>
